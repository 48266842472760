<template>
  <div>
    <!-- Waiting List Section -->
    <div class="bg-white p-3 shadow-sm rounded mb-3">
      <b-button
        class="float-right"
        variant="primary"
        size="sm"
        @click="fetchWaitingListHB"
      >
        <b-icon
          icon="arrow-clockwise"
          class="mr-1"
        />
        Refresh
      </b-button>
      <h2>Daftar Waiting List Pendonor - HB</h2>

      <b-form-group label="Pilih Lokasi MU : ">
        <registered-m-u-lists
          v-model="selectedMu"
          @input="handleInputMU"
        />
      </b-form-group>

      <b-table
        :items="watingListTable.items"
        :fields="watingListTable.fields"
        :busy="watingListTable.isBusy"
        responsive="sm"
        show-empty
      >
        <template v-slot:cell(aksi)="row">
          <b-button
            size="sm"
            variant="info"
            :to="`/dashboards/hb-registrasi/${row.item.doctor_examination_id}`"
          >
            <i class="ri-stethoscope-fill mr-1" />
            Detail
          </b-button>
        </template>
        <template v-slot:cell(status)="row">
          <b-badge variant="warning">
            Proses
          </b-badge>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-2" />
            <strong>Memuat...</strong>
          </div>
        </template>
        <template #table-empty>
          <div class="hp-p1-body text-center my-2">
            <strong>Data tidak ditemukan</strong>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="watingListTable.currentPage"
        align="right"
        :total-rows="watingListTable.total"
        :per-page="watingListTable.perPage"
        @change="handleWatingListPageChange"
      />
    </div>

    <!-- All HB List Section -->
    <div class="bg-white p-3 shadow-sm rounded mb-3 mt-5">
      <div class="p-3">
        <h2>Daftar List Pendonor - HB</h2>
        <span>Semua ( {{ table.total }} )</span>
        <div class="row no-gutters w-full float-right">
          <b-input-group>
            <b-button
              class="float-right"
              variant="primary"
              size="sm"
              @click="fetchAllHB"
            >
              <b-icon
                icon="arrow-clockwise"
                class="mr-1"
              />
              Refresh
            </b-button>

            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
                @click="fetchAllHB"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debouncedSearch"
            />
          </b-input-group>
        </div>
      </div>

      <b-table
        class="mt-3"
        :items="table.items"
        :fields="table.fields"
        :busy="table.isBusy"
        responsive="sm"
        show-empty
      >
        <template v-slot:cell(aksi)="row">
          <b-button
            size="sm"
            variant="info"
            :to="`/dashboards/hb/${row.item.id}`"
          >
            <i class="ri-stethoscope-fill mr-1" />
            Detail
          </b-button>
        </template>
        <template v-slot:cell(status)="row">
          <b-badge
            :variant="
              row.item.status === 'Rejected'
                ? 'danger'
                : row.item.status === 'Approved'
                  ? 'info'
                  : 'primary'
            "
          >
            {{
              row.item.status === "Rejected"
                ? "Ditolak"
                : row.item.status === "Approved"
                  ? "Diterima"
                  : "Selesai"
            }}
          </b-badge>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-2" />
            <strong>Memuat...</strong>
          </div>
        </template>
        <template #table-empty>
          <div class="hp-p1-body text-center my-2">
            <strong>Data tidak ditemukan</strong>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="table.currentPage"
        align="right"
        :total-rows="table.total"
        :per-page="table.perPage"
        @change="handlePageChange"
      />
    </div>
  </div>
</template>

<script setup>
import {
  BButton,
  BFormInput,
  BInputGroup,
  BIcon,
  BFormGroup,
  BTable,
  BPagination,
  BBadge,
  BSpinner,
} from 'bootstrap-vue'
import { ref, onMounted } from 'vue'
import { debounce } from 'lodash'
import manageHbAPI from '../../../../../../api/manageHB/manageHbAPI'
import RegisteredMULists from '../../../../../../components/shared/RegisteredMULists.vue'

const selectedMu = ref(null)

const watingListTable = ref({
  total: 0,
  perPage: 5,
  currentPage: 1,
  isBusy: false,
  items: [],
  fields: [
    { key: 'status', label: 'Status' },
    { key: 'name', label: 'Nama', sortable: true },
    { key: 'address', label: 'Alamat', sortable: true },
    { key: 'aksi', label: 'Aksi' },
  ],
})

const table = ref({
  total: 0,
  perPage: 10,
  currentPage: 1,
  isBusy: false,
  items: [],
  search: '',
  fields: [
    { key: 'status', label: 'Status' },
    { key: 'name', label: 'Nama', sortable: true },
    { key: 'kupper_sulfat', label: 'Kupper Sulfat', sortable: true },
    { key: 'officer_name', label: 'Nama Petugas', sortable: true },
    { key: 'aksi', label: 'Aksi' },
  ],
})

const fetchWaitingListHB = async () => {
  watingListTable.value.isBusy = true
  try {
    const params = {
      per_page: watingListTable.value.perPage,
      page: watingListTable.value.currentPage,
    }

    if (selectedMu.value?.type === 'hospital' && selectedMu.value?.hospital_id) {
      params.hospital_id = selectedMu.value.hospital_id
    } else if (selectedMu.value?.type === 'blood_van' && selectedMu.value?.id) {
      params.blood_van_id = selectedMu.value.id
    }

    const { data: response } = await manageHbAPI.getWaiting(params)
    watingListTable.value.items = response.data.data
    watingListTable.value.currentPage = response.data.current_page
    watingListTable.value.total = response.data.total
  } finally {
    watingListTable.value.isBusy = false
  }
}

const fetchAllHB = async () => {
  table.value.isBusy = true

  const params = {
    per_page: table.value.perPage,
    page: table.value.currentPage,
    search: table.value.search,
  }

  try {
    const { data: response } = await manageHbAPI.getAll(params)
    table.value.items = response.data.data
    table.value.currentPage = response.data.current_page
    table.value.total = response.data.total
  } finally {
    table.value.isBusy = false
  }
}

const debouncedSearch = debounce(() => {
  table.value.currentPage = 1 // Reset to the first page on search
  fetchAllHB()
}, 300) // Increased debounce time for better user experience

const handleWatingListPageChange = async page => {
  watingListTable.value.currentPage = page
  await fetchWaitingListHB()
}

const handlePageChange = async page => {
  table.value.currentPage = page
  await fetchAllHB()
}

function handleInputMU(selectedValue) {
  console.log('Selected value:', selectedValue)
  selectedMu.value = selectedValue
  console.log('selectedMu value (id):', selectedMu.value)

  fetchWaitingListHB()
}

onMounted(async () => {
  await fetchWaitingListHB()
  await fetchAllHB()
})
</script>
